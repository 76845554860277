import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import logoFCFM from '../assets/img/l_fcfm.png';
import logoCMMEdu from '../assets/img/l_cmmedu.png';
import logoFondef from '../assets/img/l_fondef.jpg';
import logoCpeip from '../assets/img/l_cpeip.png';
import logoConsorcio from '../assets/img/l_consorcio.jpg';
import logoOei from '../assets/img/l_oei.png';
import logoUnesco from '../assets/img/l_unesco.jpg';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const navigate = useNavigate();

    return (
        <>
            <Box width={"100%"} height={40} backgroundColor={"#eb947e"}></Box>
            <Grid rowSpacing={1} container sx={{
                width: "100%",
                maxWidth: "940px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "space-between",
                margin: "20px auto"
            }}>
                <Grid item xs={6} sm={3.5} md={1.5}>
                    <Box
                        component="img"
                        sx={{
                            maxHeight: "80px"
                        }}
                        alt="FCFM"
                        src={logoFCFM}
                    />
                </Grid>
                <Grid item xs={6} sm={3.5} md={1.5} pl={{"xs": "0", "sm": "20px"}} mt={{"xs": "10px", "sm": "0"}}>
                    <Box
                        component="img"
                        sx={{
                            maxHeight: "70px"
                        }}
                        alt="CMMEdu"
                        src={logoCMMEdu}
                    />
                    </Grid>
                <Grid item xs={8} sm={5} md={3} width="100%" textAlign={"left"} paddingLeft={{xs: "10%", "sm": "0"}}>
                <Box
                    component="img"
                    sx={{
                        maxHeight: "60px",
                        marginLeft: {"xs": "0", "sm": "50px"}
                        
                    }}
                    alt="FONDEF"
                    src={logoFondef}
                />
                </Grid>
                <Grid item xs={4} sm={3} md={1.5}>
                <Box
                    component="img"
                    sx={{
                        maxHeight: "65px"
                    }}
                    alt="CPEIP"
                    src={logoCpeip}
                />
                </Grid>
                <Grid item xs={4} sm={3} md={1.5}>
                <Box
                    component="img"
                    sx={{
                        maxHeight: "70px"
                    }}
                    alt="CPEIP"
                    src={logoConsorcio}
                />
                </Grid>
                <Grid item xs={4} sm={3} md={1.5}>
                <Box
                    component="img"
                    sx={{
                        maxHeight: "40px"
                    }}
                    alt="OEI"
                    src={logoOei}
                />
                </Grid>
                <Grid item xs={4} sm={3} md={1.5}>
                <Box
                    component="img"
                    sx={{
                        maxHeight: "55px",
                        marginTop: "10px"
                    }}
                    alt="Unesco"
                    src={logoUnesco}
                />
                </Grid>
            </Grid>
            <hr></hr>
            <Grid sx={{
                width: "100%",
                maxWidth: "940px",
                textAlign: "center",
                margin: "0 auto",
                padding: "0 20px"
            }}>
                <Typography textAlign={"center"} fontSize={{"xs": "0.8em", "sm": "0.85em"}} variant="p">© RedFID. Todos los derechos reservados, excepto donde se indique lo contrario.</Typography>
                <Box minWidth={"100%"} display={"flex"} justifyContent={"center"}>
                    <Link target="_blank" rel="noopener" onClick={() => navigate("/terminos-y-condiciones")}><Typography variant="a" m={"5px 0"} fontSize={{"xs": "0.8em", "sm": "0.85em"}}>Términos y condiciones</Typography></Link>
                </Box>
                <Typography textAlign={"center"} fontSize={{"xs": "0.8em", "sm": "0.85em"}} variant="p" mb={"10px"}>Copyright © {new Date().getFullYear()} - RedFID</Typography>
            </Grid>
        </>
    );

};

export default Footer;