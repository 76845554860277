import React from 'react';
import { Grid, Typography, Link, List, ListItem } from '@mui/material';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const Register = ({ keycloak }) => {

    const { trackPageView } = useMatomo();
    const navigate = useNavigate();

    const onLoad = () => {
        document.title = "Términos y condiciones | RedFID";
        window.scrollTo(0, 0);
        trackPageView();
        return <></>;
    }

    return (
        keycloak.didInitialize &&
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: "center" }}>
            {onLoad()}
            <Header keycloak={keycloak} />
            <Grid
                component="div"
                sx={{
                    flexGrow: 1,
                    maxWidth: '940px',
                    width: '100%',
                    padding: "0 20px",
                    margin: "30px 20px 50px 20px",
                }}
            >
                <Typography onClick={() => navigate("/")} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                    <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                    <Typography variant="back">Volver al Home</Typography>
                </Typography>
                <Typography textAlign={"center"} variant='title' color="#40b4ba" fontSize={{ "xs": "1.2em", "md": "1.6em" }}>Términos y condiciones</Typography>
                <Typography textAlign={"center"} variant='subtitle' m={"20px 0"} fontSize={{ "xs": "1.1em", "md": "1.3em" }}>1. Aspectos generales</Typography>
                <Typography mt={"20px"} variant='subtitle' fontSize={{ "xs": "1.1em", "md": "1.3em" }}>La plataforma</Typography>
                <Typography variant='p'>
                    La plataforma a la que está accediendo se enmarca en el proyecto FONDEF ID21I10067, que busca desarrollar un sistema de apoyo para el fortalecimiento de la
                    formación inicial docente en educación básica y media de matemáticas, con foco en innovación en docencia. El sistema de apoyo descrito (en adelante “plataforma
                    RedFID” o “RedFID” indistintamente) es un sistema informático que tiene por objetivo que los formadores puedan: desarrollar colaboración con pares en torno a
                    tareas claves para la formación de los profesores; generar oportunidades de desarrollo profesional permanente permitiéndoles enriquecer su práctica docente;
                    desarrollar nuevos conocimientos disciplinarios y didácticos que les permitan innovar en docencia; y acceder a recursos de aprendizaje especializados para la
                    enseñanza de la matemática.
                </Typography>
                <Typography mt={"20px"} variant='subtitle' fontSize={{ "xs": "1.1em", "md": "1.3em" }}>Usuarios</Typography>
                <Typography variant='p'>
                    Los usuarios de la plataforma son formadores de matemática que se desempeñan en  carreras de Pedagogía en Educación Básica, Pedagogía en Educación Parvularia,
                    y Pedagogía en Matemática impartidas en distintas universidades del país.
                </Typography>
                <Typography mt={"20px"} variant='subtitle' fontSize={{ "xs": "1.1em", "md": "1.3em" }}>Registro y acceso</Typography>
                <Typography variant='p'>
                    Para poder pertenecer y participar en RedFID, el formador deberá completar un formulario de solicitud de inscripción en el cual se consigne su nombre completo, correo
                    electrónico, programa de formación al cual pertenece, universidades en donde realiza clases en carreras de Pedagogía Básica o Pedagogía en Matemáticas, y una carta o
                    documento que acredite su afiliación a dicha institución. Una vez revisado y confirmado los antecedentes entregados por la persona, se habilitará el ingreso a la plataforma
                    entregando un usuario y contraseña. Al sistema de apoyo se accede a través de la página de inicio de RedFID (<Link color="#40b4ba" target={"_blank"} href={"https://www.redfid.cl/"}>https://www.redfid.cl/</Link>),
                    o desde cualquiera de sus sub-plataformas, con las credenciales de usuario que son entregadas por el equipo de administración de la plataforma. Estas credenciales son únicas
                    para todos los ambientes de RedFID. Los campos almacenados por el sistema de autenticación son un nombre de usuario, contraseña, correo electrónico, nombre y apellido.
                    Estos elementos son compartidos entre las plataformas, sin perjuicio de que cada una de estas pueda solicitar datos adicionales al usuario.
                </Typography>
                <Typography textAlign={"center"} variant='subtitle' fontSize={{ "xs": "1.1em", "md": "1.3em" }} m={"40px 0"}>2. Sobre la información y datos personales</Typography>
                <Typography mt={"20px"} variant='subtitle' fontSize={{ "xs": "1.1em", "md": "1.3em" }}>Información recolectada por RedFID</Typography>
                <Typography variant='p'>
                    La plataforma RedFID almacena toda la información que ingresan los usuarios en sus tres componentes: Aprendizaje Profesional, Comunidades y Recursos, incluyendo los
                    registros de navegación de los usuarios. La información es recolectada para proveer los servicios que entrega la plataforma RedFID y desarrollar mejoras a las funcionalidades
                    que vayan en beneficio directo de los usuarios. Adicionalmente, los datos almacenados son utilizados de forma anonimizada para investigación sobre sistemas de apoyo a la
                    formación inicial docente en el marco del proyecto FONDEF ID21I10067. RedFID no difundirá información de carácter personal ni otro tipo de información almacenados en la
                    plataforma en ninguna forma.
                </Typography>
                <Typography mt={"20px"} variant='subtitle' fontSize={{ "xs": "1.1em", "md": "1.3em" }}>Propiedad intelectual y derechos de autor</Typography>
                <Typography variant='p'>
                    La propiedad intelectual de todos los documentos o productos desarrollados sobre la plataforma son de exclusiva propiedad de quienes los crearon y RedFID no los
                    utilizará bajo ningún pretexto sin consentimiento explícito de sus autores.
                </Typography>
                <Typography mt={"20px"} variant='subtitle' fontSize={{ "xs": "1.1em", "md": "1.3em" }}>Seguridad de la información recolectada</Typography>
                <Typography variant='p'>
                    Toda la información recolectada por RedFID, es almacenada cumpliendo con la <Link color="#40b4ba" target={"_blank"} href={"https://uchile.cl/informacion-general/politica-de-privacidad-de-la-informacion-y-datos-personales"}>Política de Privacidad de la Información y Datos Personales de la Universidad de Chile</Link>.
                    RedFID protege toda la información personal de los usuarios para evitar modificación, divulgación, destrucción o acceso no autorizado a los datos almacenados. Para esto
                    se utilizan herramientas y prácticas estándares de seguridad de la industria.
                </Typography>
                <Typography mt={"20px"} variant='subtitle' fontSize={{ "xs": "1.1em", "md": "1.3em" }}>Uso de cookies</Typography>
                <Typography variant='p'>
                    Para el correcto funcionamiento de la plataforma se utilizan cookies, cuyo principal uso es entregar al usuario una experiencia continua entre las componentes de la plataforma,
                    recordándolo a través de todos los servicios que accede. Adicionalmente, utilizamos cookies para obtener estadísticas de uso general en los servicios Google Analytics. En este
                    servicio no enviamos datos personales de los usuarios y la información recopilada se utiliza para evaluar las tasas de uso de la plataforma y mejorar los flujos e interfaces.
                </Typography>
                <Typography textAlign={"center"} variant='subtitle' fontSize={{ "xs": "1.1em", "md": "1.3em" }} m={"40px 0"}>3. Sobre el uso de la plataforma</Typography>
                <Typography variant='p'>
                    Para el correcto funcionamiento de la plataforma, el usuario acepta el código de honor RedFID, que conlleva asumir las siguientes conductas y compromisos:
                </Typography>
                <List sx={{ listStyleType: 'disc' }} mr={"40px"}>
                    <ListItem sx={{ ml: 4, pr: 4, display: 'list-item' }}>No compartir la clave de acceso con terceras personas, ni difundir información de otros participantes de la plataforma fuera de ésta.</ListItem>
                    <ListItem sx={{ ml: 4, pr: 4, display: 'list-item' }}>No ingresar información falsa o imprecisa en ninguna de las componentes de la plataforma.</ListItem>
                    <ListItem sx={{ ml: 4, pr: 4, display: 'list-item' }}>Mantener un ambiente de respeto y tolerancia en la comunicación con otros usuarios.</ListItem>
                    <ListItem sx={{ ml: 4, pr: 4, display: 'list-item' }}>Mantener actualizada su información de perfil y participar en encuestas o evaluaciones de la plataforma.</ListItem>
                    <ListItem sx={{ ml: 4, pr: 4, display: 'list-item' }}>No realizar actividades deshonestas que incidan en su participación, o que perjudiquen a otros participantes.</ListItem>
                    <ListItem sx={{ ml: 4, pr: 4, display: 'list-item' }}>Respetar las instrucciones y participar de forma honesta en todas las actividades de la plataforma.</ListItem>
                    <ListItem sx={{ ml: 4, pr: 4, display: 'list-item' }}>
                        Compartir únicamente material que tenga permiso explícito para compartir o que esté bajo licencias de uso libre. En caso de que la información compartida sea de terceros,
                        esta debe ser citada según establezca su licencia de uso.
                    </ListItem>
                    <ListItem sx={{ ml: 4, pr: 4, display: 'list-item' }}>Respetar la propiedad intelectual de otros usuarios y no plagiar su trabajo.</ListItem>
                    <ListItem sx={{ ml: 4, pr: 4, display: 'list-item' }}>
                        Notificar al equipo de soporte técnico si se detectan problemas técnicos o de seguridad en la plataforma. De igual forma, notificar inmediatamente cualquier actividad sospechosa o
                        fraudulenta que se detecte en la plataforma.
                    </ListItem>
                </List>
                <Typography variant='p'>
                    RedFID se reserva el derecho a caducar la cuenta de aquellos usuarios que violen el código de honor.
                </Typography>
            </Grid>
            <Footer />
        </Grid>
    );
};

export default Register;
