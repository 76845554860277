import React, { useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const NotFound = ({ keycloak }) => {

  const { trackPageView } = useMatomo();
  const navigate = useNavigate();

  useEffect(() => {
    if (keycloak.didInitialize) {
      document.title = "Página no encontrada | RedFID";
      trackPageView();
    }
  }, [keycloak]);

  return (
    keycloak.didInitialize &&
    <Box style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header keycloak={keycloak} />
      <Box
        component="div"
        p={"0 20px"}
        sx={{
          flexGrow: 1,
          m: '30px auto',
          maxWidth: '940px',
          textAlign: 'center',
        }}
      >
        <Typography variant='title' mb={"20px"}>Página no encontrada</Typography>
        <Typography onClick={() => { keycloak.didInitialize && navigate("/") }} variant='a'>Volver al Home</Typography>
      </Box>
      <Footer />
    </Box>
  );
}

export default NotFound;