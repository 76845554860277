import React, { useState } from 'react';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';

const links = {
    "home": {
        "url": process.env.REACT_APP_HOME_URL,
        "name": "Inicio",
        "selected": true
    },
    "cursos": {
        "url": process.env.REACT_APP_CURSOS_URL,
        "name": "Aprendizaje Profesional",
        "selected": false
    },
    "comunidades": {
        "url": process.env.REACT_APP_DISCOURSE_URL,
        "name": "Comunidades",
        "selected": false
    },
    "recursos": {
        "url": process.env.REACT_APP_RECURSOS_URL,
        "name": "Recursos",
        "selected": false
    },
    "account": {
        "url": process.env.REACT_APP_ISSUER + "/realms/REDFID/account",
        "selected": false
    },
    "logout": {
        "selected": false,
        "name": "Cerrar sesión"
    },
    "register": {
        "url": process.env.REACT_APP_HOME_URL + "/formulario-de-registro",
        "selected": false,
        "name": "Registrarse"
    },
    "login": {
        "selected": false,
        "name": "Iniciar sesión"
    }
};

const HeaderButton = ({ keycloak, link, label }) => {

    const navigate = useNavigate();

    const [color, setColor] = useState("#40b4ba");
    const [backgroundColor, setBackgroundColor] = useState("white");

    const selectedLink = links[link];

    const handleHover = () => {
        setColor(color === "#40b4ba" ? "white" : "#40b4ba");
        setBackgroundColor(backgroundColor === "white" ? "#40b4ba" : "white");
    };

    const handleClick = () => {
        if(link === "home") {
            navigate("/");
        } else if (link === "login") {
            keycloak.login();
        } else if (link === "logout") {
            keycloak.logout();
        } else {
            window.location.href = links[link]["url"];
        }
    }

    return (
        <Box
            onClick={handleClick}
            href={selectedLink.url}
            style={{
                backgroundColor: backgroundColor,
                display: 'flex',
                alignItems: 'center',
                padding: '0 30px',
                cursor: 'pointer',
                textDecoration: 'none',
                fontWeight: 'bold',
            }}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
        >   
            {link === "account" && <PersonIcon sx={{"color": color, "paddingRight": "10px" }} />}
            <p style={{color: color}}>{label ? label : selectedLink.name}</p>
        </Box>
    );

};

export default HeaderButton;