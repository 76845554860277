import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Box, Grid, Typography, TextField, MenuItem, IconButton, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { formatFileSize } from '../utils/formatters';
import FileUpload from '../utils/fileUpload';
import api from '../API';

const RegisterForm = ({ setRecaptchaToken }) => {

    const navigate = useNavigate();
    const { trackEvent } = useMatomo();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [career, setCareer] = useState("none");
    const [fid, setFid] = useState("none");
    const [universities, setUniversities] = useState("");
    const [certificationDocument, setCertificationDocument] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formFailed, setFormFailed] = useState(false);
    const [formError, setFormError] = useState("");
    const [errors, setErrors] = useState({});


    const sendRegisterForm = async (api, formData) => {
        try {
            const response = await axios.post(api.api_url + "/register", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response;
        } catch (error) {
            return error.response
        }
    }

    const validateForm = () => {
        let valid = true;
        let errors = {};

        if (!firstName) {
            errors.firstName = "Por favor ingrese su nombre.";
            valid = false;
        } else if (firstName.length < 3 || firstName.length > 50) {
            errors.firstName = "El nombre debe tener entre 3 y 50 caracteres.";
            valid = false;
        }
        if (!lastName) {
            errors.lastName = "Por favor ingrese su apellido.";
            valid = false;
        } else if (lastName.length < 3 || lastName.length > 50) {
            errors.lastName = "El apellido debe tener entre 3 y 50 caracteres.";
        }
        if (!email) {
            errors.email = "Por favor ingrese su correo electrónico.";
            valid = false;
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            errors.email = "Por favor ingrese un correo electrónico válido.";
            valid = false;
        }
        if (career === "none") {
            errors.career = "Por favor seleccione una opción.";
            valid = false;
        }
        if (fid === "none") {
            errors.fid = "Por favor seleccione una opción.";
            valid = false;
        }
        if (!universities) {
            errors.universities = "Por favor ingrese las universidades en donde imparte clases.";
            valid = false;
        } else if (universities.length < 3 || universities.length > 300) {
            errors.universities = "Las universidades deben tener entre 3 y 300 caracteres.";
            valid = false;
        }
        if (!certificationDocument) {
            errors.certificationDocument = "Por favor suba un documento que acredite su afiliación institucional.";
            valid = false;
        } else if (![".pdf", ".doc", ".docx", ".png", ".jpg", ".jpeg"].includes(certificationDocument.name.slice(-4).toLowerCase())) {
            errors.certificationDocument = "El archivo debe ser un documento en formato PDF, DOC, DOCX, PNG, JPG o JPEG.";
        } else if (certificationDocument.size > 4194304) {
            errors.certificationDocument = "El archivo no debe superar los 4 MB.";
        }
        return [valid, errors];
    }

    const handleSubmit = async () => {
        const [isValid, tempErrors] = validateForm();
        if (!isValid) {
            setErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' });
        setRecaptchaToken(token);
        const formData = new FormData();
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("email", email);
        formData.append("career", career);
        formData.append("fid", fid);
        formData.append("universities", universities);
        formData.append("certification_document", certificationDocument);
        formData.append("g-recaptcha-response", token);
        const response = await sendRegisterForm(api, formData);
        trackEvent({ category: 'Home', action: 'Solicitó registrarse' });
        if (response.status === 200) {
            if (response.data.status === 100) {
                setFormSubmitted(true);
            } else {
                setFormFailed(true);
                setFormError(response.data.message);
            }
        } else {
            setFormFailed(true);
            setFormError(response.data.message);
        }
        setFormLoading(false);
    };

    const handleFileChange = (event) => {
        setCertificationDocument(event.target.files[0]);
        setErrors(prevErrors => ({ ...prevErrors, certificationDocument: '' }));
    };

    const resetFileInput = () => {
        setCertificationDocument(null);
        setErrors(prevErrors => ({ ...prevErrors, certificationDocument: '' }));
    };

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Nombre(s)"
                        variant="outlined"
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, firstName: '' }));
                        }}
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                        disabled={formLoading || formSubmitted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Apellido(s)"
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => {
                            setLastName(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, lastName: '' }));
                        }}
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                        disabled={formLoading || formSubmitted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Correo electrónico"
                        variant="outlined"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, email: '' }));
                        }}
                        error={!!errors.email}
                        helperText={errors.email}
                        disabled={formLoading || formSubmitted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        fullWidth
                        label="Carrera o programa de formación en donde imparte clases"
                        value={career}
                        onChange={(e) => {
                            setCareer(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, career: '' }));
                        }}
                        error={!!errors.career}
                        helperText={errors.career}
                        variant="outlined"
                        disabled={formLoading || formSubmitted}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="Pedagogía en Educación Básica">Pedagogía en Educación Básica</MenuItem>
                        <MenuItem value="Pedagogía en Matemáticas">Pedagogía en Matemáticas</MenuItem>
                        <MenuItem value="Pedagogía en Educación Parvularia">Pedagogía en Educación Parvularia</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        fullWidth
                        label="¿Participas de la formación inicial docente en matemáticas?"
                        value={fid}
                        onChange={(e) => {
                            setFid(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, fid: ''}));
                        }}
                        error={!!errors.fid}
                        helperText={errors.fid}
                        variant="outlined"
                        disabled={formLoading || formSubmitted}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="Sí">Sí</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Universidades en donde imparte clases"
                        variant="outlined"
                        multiline
                        rows={3}
                        value={universities}
                        onChange={(e) => {
                            setUniversities(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, universities: '' }));
                        }}
                        error={!!errors.universities}
                        helperText={errors.universities}
                        disabled={formLoading || formSubmitted}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='p' mb={"20px"}>
                        Documento que acredite su afiliación institucional
                    </Typography>
                    <Grid display={"flex"} alignItems={"center"}>
                        <Button
                            component="label"
                            role={undefined}
                            variant="orange"
                            tabIndex={-1}
                            onChange={handleFileChange}
                            startIcon={<CloudUploadIcon />}
                            disabled={formLoading || formSubmitted}
                        >
                            Subir archivo
                            <FileUpload type="file" onChange={handleFileChange} accept={".pdf, .doc, .docx, .png, .jpg, .jpeg"} />
                        </Button>
                        <Typography ml={"8px"} sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                            {certificationDocument && (
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                    <Typography sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                        {certificationDocument ? `${certificationDocument.name} (${formatFileSize(certificationDocument.size)})` : "Por favor seleccione un archivo..."}
                                    </Typography>
                                    <IconButton disabled={formLoading || formSubmitted} sx={{ marginLeft: "10px" }} size="small" onClick={resetFileInput}>
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            )}
                        </Typography>
                        {errors.certificationDocument ? <Typography sx={{ marginTop: "10px", fontSize: "0.75rem" }} variant="p" color="error">{errors.certificationDocument}</Typography> : <></>}
                    </Grid>
                </Grid>
            </Grid>
            <Box textAlign={"center"} >
                <Button sx={{ "fontSize": { "xs": "0.7em", "sm": "0.9em" } }} onClick={handleSubmit} type="submit" variant="green" disabled={formLoading || formSubmitted || formFailed} style={{ margin: "30px 0 20px 0" }} startIcon={<HowToRegIcon />}>
                    {(formLoading) ? "Enviando..." : (formSubmitted) ? "Solicitud enviada" : (formFailed) ? "Error" : "Enviar"}
                </Button>
            </Box>
            {formSubmitted && (
                <Box textAlign={"center"}>
                    <Typography variant='p' fontSize={"0.85em"} textAlign={"center"}>La solicitud de registro ha sido recibida exitosamente.</Typography>
                    <Typography variant='p' fontSize={"0.85em"} textAlign={"center"}>El staff se contactará contigo a la brevedad.</Typography>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography onClick={() => { navigate("/") }} variant='a' fontSize={"0.85em"} textAlign={"center"} marginRight={"15px"}>Volver al Home</Typography>
                    </Box>
                </Box>
            )}
            {formFailed && (
                <Box textAlign={"center"}>
                    <Typography variant='error' fontSize={"0.85em"} textAlign={"center"}>{formError}</Typography>
                </Box>
            )}
        </>
    );
}

export default RegisterForm;