import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Zoom from '@mui/material/Zoom';
import HeaderButton from './HeaderComponents/HeaderButton';
import HeaderUser from './HeaderComponents/HeaderUser';
import HeaderLogo from './HeaderComponents/HeaderLogo';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';

const Header = ({ keycloak }) => {

    const [displayMobileMenu, setDisplayMobileMenu] = useState("none");

    const handleDisplayMobileMenu = (menu) => {
        if (displayMobileMenu === menu) {
            setDisplayMobileMenu("none");
        } else {
            setDisplayMobileMenu(menu);
        }
    };

    const useMobileVersion = useMediaQuery({
        query: '(max-width: 920px)'
    });

    const isSmallScreen = useMediaQuery({
        query: '(max-width: 940px)'
    });

    const rightPosition = isSmallScreen ? "0px" : "calc((100% - 940px) / 2)";

    const buttonsMenuRef = useRef(null);
    const userMenuRef = useRef(null);
    const menuButton = useRef(null);
    const userButton = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (buttonsMenuRef.current && !buttonsMenuRef.current.contains(event.target) &&
                userMenuRef.current && !userMenuRef.current.contains(event.target) && 
                menuButton.current && !menuButton.current.contains(event.target) &&
                userButton.current && !userButton.current.contains(event.target)) {
                setDisplayMobileMenu("none");
            } else if (!useMobileVersion && userMenuRef.current && !userMenuRef.current.contains(event.target) && userButton.current && !userButton.current.contains(event.target)) {
                setDisplayMobileMenu("none");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [displayMobileMenu]);

    return (
        <>
            <Box width={"100%"} height={17} backgroundColor={"#40b4ba"}></Box>
            <Box width={"100%"} height={72} boxShadow={"0px 6px 4px -4px #cccccc;"} display={"flex"} alignItems={"center"}>
                {useMobileVersion ?
                    <Grid container p={"0 20px"} fontSize={"0.95em"} width={"940px"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} m={"auto"}>
                        <Grid item xs={4}>
                            <IconButton ref={menuButton} onClick={() => handleDisplayMobileMenu("buttons")}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={4}>
                            <HeaderLogo link={"recursos"} />
                        </Grid>
                        <Grid item xs={4}>
                            <HeaderUser userButton={userButton} handleDisplayMobileMenu={handleDisplayMobileMenu} keycloak={keycloak} mobile={true} />
                        </Grid>
                    </Grid>
                    :
                    <Box p={"0 20px"} fontSize={"0.95em"} width={"940px"} display={"flex"} alignItems={"center"} justifyContent={"start"} m={"auto"}>
                        <HeaderLogo link={"home"} />
                        <HeaderButton keycloak={keycloak} link="cursos" />
                        <HeaderButton keycloak={keycloak} link="comunidades" />
                        <HeaderButton keycloak={keycloak} link="recursos" />
                        <HeaderUser userButton={userButton} handleDisplayMobileMenu={handleDisplayMobileMenu} keycloak={keycloak} mobile={false} />
                    </Box>
                }
            </Box>
            {useMobileVersion &&
                <Zoom in={displayMobileMenu === "buttons"}>
                    <Box width={"100%"} position={"absolute"} top={"89px"} zIndex={10}>
                        <Box display={"flex"}>
                            <Paper ref={buttonsMenuRef} sx={{ "display": "flex", "flexDirection": "column" }}>
                                <HeaderButton keycloak={keycloak} link="home" />
                                <HeaderButton keycloak={keycloak} link="cursos" />
                                <HeaderButton keycloak={keycloak} link="comunidades" />
                                <HeaderButton keycloak={keycloak} link="recursos" />
                            </Paper>
                        </Box>
                    </Box>
                </Zoom>
            }
            <Zoom in={displayMobileMenu === "user"}>
                <Box width={"100%"} position={"absolute"} top={"89px"} right={rightPosition} zIndex={10}>
                    <Box display={"flex"} width={"100%"} justifyContent={"end"}>
                        <Paper ref={userMenuRef} sx={{ "display": "flex", "flexDirection": "column" }}>
                            {(keycloak.didInitialize && keycloak.authenticated) && <HeaderButton keycloak={keycloak} link={"account"} label={keycloak.idTokenParsed.preferred_username} />}
                            {(useMobileVersion && keycloak.didInitialize && !keycloak.authenticated) && <HeaderButton keycloak={keycloak} link="register" label={"Regístrate"} />}
                            <HeaderButton keycloak={keycloak} link={(keycloak.didInitialize && keycloak.authenticated) ? "logout" : "login"} />
                        </Paper>
                    </Box>
                </Box>
            </Zoom>
        </>
    );
};

export default Header;
