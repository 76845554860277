import { useNavigate } from "react-router-dom";

const LegacyLogout = ({ keycloak }) => {

    const navigate = useNavigate();

    const onLoad = () => {
        if (keycloak.authenticated) {
            const redirectUri = window.location.origin + "/";
            keycloak.logout({ redirectUri: redirectUri });
        } else {
            navigate('/');
        }
    }

    return (
        <>{onLoad()}</>
    );
};

export default LegacyLogout;