import React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RegisterForm from './RegisterForm';


const Register = ({ keycloak, setRecaptchaToken }) => {

    const { trackPageView } = useMatomo();
    const navigate = useNavigate();

    const onLoad = () => {
        document.title = "Formulario de registro | RedFID";
        window.scrollTo(0, 0);
        trackPageView();
        return <></>;
    }

    return (
        keycloak.didInitialize &&
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: "center" }}>
            {onLoad()}
            <Header keycloak={keycloak} />
            <Grid
                component="div"
                sx={{
                    flexGrow: 1,
                    maxWidth: '940px',
                    margin: "30px 20px",
                }}
            >
                <Typography onClick={() => navigate("/")} style={{ display: 'flex', alignItems: 'center', color: '#40b4ba', 'width': 'fit-content' }}>
                    <ArrowBackIcon sx={{ fontSize: '1em' }} variant="back" />
                    <Typography variant="back">Volver al Home</Typography>
                </Typography>
                <Typography textAlign={"center"} variant='title' color="#40b4ba" mb={"20px"} fontSize={{ "xs": "1.2em", "md": "1.6em" }}>Regístrate en RedFID</Typography>
                <Typography variant='p' mb={"20px"}>
                    Para poder pertenecer a la Comunidad de Formadores RedFID y acceder al curso de Indagación de la Práctica Docente, los talleres
                    de aprendizaje profesional y las actividades de la comunidad, complete la siguiente información y suba un documento que acredite
                    su afiliación a la carrera o programa de formación en donde imparte clases. Una vez validada la información, se habilitará el
                    ingreso a la plataforma entregando un usuario y contraseña.
                </Typography>
                <Typography textAlign={"center"} variant='p' mb={"20px"}>
                    En caso de que desee mayor información, escríbanos a <Link color={"#40b4ba"} href={"mailto:contacto@redfid.cl"}>contacto@redfid.cl</Link>.
                </Typography>
                <RegisterForm setRecaptchaToken={setRecaptchaToken} />
            </Grid>
            <Footer />
        </Grid>
    );
};

export default Register;
