import React, { useState, useEffect, cloneElement } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import theme from './theme';
import oidcConfiguration from './Context/oidcConfig';
import matomoConfiguration from './Context/matomoConfig';
import SilentSSOCheck from './Context/SilentSSORedirect';
import LegacyLogout from './Context/LegacyLogout';
import { ThemeProvider } from '@mui/material/styles';
import NotFound from './Errors/NotFound';
import Home from './Content/Home';
import Register from './Content/Register';
import TermsAndConditions from './Content/TermsAndConditions';
import News from './Content/News';
import { KeycloakProvider, useKeycloak } from "keycloak-react-web"
import Keycloak from 'keycloak-js';
import axios from 'axios';
import api from './API';


const keycloakSetting = oidcConfiguration;
const matomoSetting = matomoConfiguration;

const authInstance = new Keycloak(keycloakSetting);
const matomoInstance = createInstance(matomoSetting);

function App() {

  const [news, setNews] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const getNews = async () => {
    const response = await axios.get(api.api_url + "/get_news")
    setNews(response.data.news.sort((a, b) => b.priority - a.priority));
  }

  useEffect(() => {
    if (!news) {
      getNews();
    }
  }, [news]);

  useEffect(() => {
    if (recaptchaToken === "") {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'form' }).then(token => {
          setRecaptchaToken(token);
        });
      });
    }
  }, []);

  return (
    (news && recaptchaToken) && <KeycloakProvider client={authInstance} initOptions={{
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    }}>
      <MatomoProvider value={matomoInstance}>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/" element={<ProtectedRoute><Home news={news} /></ProtectedRoute>} />
              <Route path="/formulario-de-registro" element={<ProtectedRoute><Register setRecaptchaToken={setRecaptchaToken} /></ProtectedRoute>} />
              <Route path="/terminos-y-condiciones" element={<ProtectedRoute><TermsAndConditions /></ProtectedRoute>} />
              <Route path="/events/*" element={<ProtectedRoute><News news={news} /></ProtectedRoute>} />
              <Route path="/wp-login.php" element={<ProtectedRoute><LegacyLogout /></ProtectedRoute>} />
              <Route path="/silent-check-sso.html" element={<SilentSSOCheck />} />
              <Route path="*" element={<ProtectedRoute><NotFound /></ProtectedRoute>} />
            </Routes>
          </Router>
        </ThemeProvider>
      </MatomoProvider>
    </KeycloakProvider>
  );
}


const ProtectedRoute = ({ children }) => {
  const { keycloak } = useKeycloak();

  keycloak.onTokenExpired = () => {
    keycloak.updateToken(50);
  };

  const childWithProps = React.Children.map(children, child => {
    return cloneElement(child, { keycloak: keycloak });
  });

  return <>{childWithProps}</>;
};

export default App;