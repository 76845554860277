import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Paper } from '@mui/material';
import { useMediaQuery, useTheme } from "@mui/material";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import kperezImage from '../assets/img/kperez.jpg';
import azamoranoImage from '../assets/img/azamorano.jpg';
import lcasisImage from '../assets/img/lcasis.png';
import YoutubeIframe from '../utils/YoutubeIframe';
import Carousel from 'react-multi-carousel';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import fullLogo from '../assets/img/full_logo.png';
import homeBg from '../assets/img/home_bg.jpg';


const Home = ({ keycloak, news }) => {

    const navigate = useNavigate();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("sm"));
    const { trackPageView } = useMatomo();

    const universities = [
        "UCH", "UTA", "UDLA", "UMAG", "UDEC", "UCSC", "UACH", "UCT", "USACH", "ULA", "PUC", "UOH", "USERENA", "UDA",
        "PUCV", "UFT", "UMAYOR", "UNACH", "UNAP", "USS", "UTALCA", "UDD", "UFRO", "UMCE", "UST", "UAH",
        "UBB", "UBO", "UCEN", "UCN", "UCSH"
    ];

    const responsiveCarouselNews = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const responsiveCarousel = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 10
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };

    const NewsItem = (thisNews) => {
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Box
                    component={"img"}
                    src={
                        process.env.REACT_APP_API_URL + "/get_news_image/" + 
                        thisNews.thisNews.id + "/" + 
                        (isXs ? thisNews.thisNews.square_image : thisNews.thisNews.main_image)
                    }
                    onClick={() => {
                        if (thisNews.thisNews.external_url === "") {
                            navigate("/events/" + thisNews.thisNews.slug);
                        } else {
                            window.location.href = thisNews.thisNews.external_url;
                        }
                    }}
                    alt={thisNews}
                    sx={{
                        width: "100%",
                        height: "auto",
                        cursor: thisNews.thisNews.content.length !== 0 ? "pointer" : "default"
                    }}
                />
            </Box>
        )
    }

    const UniversityItem = (university) => {
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Box
                    component={"img"}
                    src={process.env.REACT_APP_API_URL + "/get_logo/" + university.university + "/1"}
                    alt={university}
                    sx={{
                        width: "auto",
                        height: "100px"
                    }}
                />
            </Box>
        )
    }

    const onLoad = () => {
        document.title = "RedFID";
        window.scrollTo(0, 0);
        trackPageView();
        return <></>;
    }

    return (
        keycloak.didInitialize &&
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: "center" }}>
            {onLoad()}
            <Header keycloak={keycloak} />
            <Grid
                component="div"
                sx={{
                    flexGrow: 1,
                    maxWidth: '940px',
                    margin: "30px 20px",
                    width: "100%"
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Carousel responsive={responsiveCarouselNews} infinite={true} autoPlaySpeed={5000} autoPlay={true}>
                            {
                                news.filter(
                                    n => (n.published) && (n.start_timestamp <= Date.now()) && (n.end_timestamp >= Date.now())
                                ).map(item => <NewsItem key={item} thisNews={item} />)
                            }
                        </Carousel>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ backgroundImage: `url(${homeBg})`, backgroundSize: "cover", padding: "20px" }} mt={"10px"}>
                    <Grid item xs={12} md={4}>
                        <Box height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Box
                                component="img"
                                src={fullLogo}
                                alt="RedFID"
                                sx={{
                                    width: "177px",
                                    height: "158px",
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant='mega-title' mb={"20px"} fontSize={{"xs": "1.6em", "md": "2.5em"}} textAlign={"center"}>
                            ¿Qué es RedFID?
                        </Typography>
                        <Typography variant='p' mb={"20px"}>
                            RedFID es una plataforma tecnológica que sustenta un sistema de apoyo a la Formación Inicial en matemáticas, por medio de <b>comunidades</b> de prácticas de formadores, <b>cursos</b> de desarrollo profesional auto-instruccionales y una biblioteca digital de <b>recursos</b> pedagógicos.
                        </Typography>
                        <Typography variant='p'>
                            Esta iniciativa surge gracias a los proyectos <b>FONDEF ID21I10067</b> y <b>FONDECYT REGULAR 1212067</b>, y tiene el patrocinio del <b>CPEIP</b> del Ministerio de Educación; Consorcio de Universidades del Estado de Chile (<b>CUECH</b>); y Oficina de Estados Iberoamericano, área de Educación (OEI). También forma parte de la <b>Cátedra Unesco</b> Formación de docentes para enseñar matemática en el siglo XXI, del Laboratorio de Educación del Centro de Modelamiento Matemático (<b>CMMEdu</b>) de la Universidad de Chile.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={"0px"} p={"0 20px"}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{
                            backgroundColor: "#40b4ba",
                            padding: '15px 20px',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '20px 20px 0 0'
                        }}>
                            <Typography color='white' fontSize={"1.1em"} fontWeight={"bold"} textAlign={"center"} variant='subtitle' mb={0}>
                                APRENDIZAJE PROFESIONAL
                            </Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#edf7f8",
                            padding: '20px 30px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: '0 0 20px 20px'
                        }}>
                            <Typography variant='p'>
                                En este espacio podrás acceder a un curso auto-instruccional de indagación de la práctica docente, talleres, cápsulas y webinars orientados a fortalecer tu aprendizaje profesional como formador.
                            </Typography>
                            <Typography
                                onClick={() => (window.location.href = process.env.REACT_APP_CURSOS_URL)}
                                mt={"20px"}
                                sx={{ "textDecoration": "underline", "cursor": "pointer" }}
                                variant='subtitle'
                                color='#40b4ba'
                                fontSize={"1.1em"}
                                fontWeight={"bold"}
                                textAlign={"center"}
                                mb={0}
                            >
                                VER MÁS +
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{
                            backgroundColor: "#fd756b",
                            padding: '15px 20px',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '20px 20px 0 0'
                        }}>
                            <Typography color='white' fontSize={"1.1em"} fontWeight={"bold"} textAlign={"center"} variant='subtitle' mb={0}>
                                COMUNIDADES
                            </Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#fdf1f0",
                            padding: '20px 30px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: '0 0 20px 20px'
                        }}>
                            <Typography variant='p'>
                                En este ambiente tendrás la oportunidad de generar redes y trabajo colaborativo con otros formadores al formar parte de distintas comunidades de prácticas virtuales.
                            </Typography>
                            <Typography
                                onClick={() => (window.location.href = process.env.REACT_APP_DISCOURSE_URL)}
                                mt={"20px"}
                                sx={{ "textDecoration": "underline", "cursor": "pointer" }}
                                variant='subtitle'
                                color='#fd756b'
                                fontSize={"1.1em"}
                                fontWeight={"bold"}
                                textAlign={"center"}
                                mb={0}
                            >
                                VER MÁS +
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{
                            backgroundColor: "#40b4ba",
                            padding: '15px 20px',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '20px 20px 0 0'
                        }}>
                            <Typography color='white' fontSize={"1.1em"} fontWeight={"bold"} textAlign={"center"} variant='subtitle' mb={0}>
                                RECURSOS
                            </Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: "#edf7f8",
                            padding: '20px 30px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: '0 0 20px 20px'
                        }}>
                            <Typography variant='p'>
                                Aquí podrás acceder a una biblioteca digital de recursos de aprendizaje especializados para la formación inicial docente. También podrás compartir tus propios recursos con la comunidad.
                            </Typography>
                            <Typography
                                onClick={() => (window.location.href = process.env.REACT_APP_RECURSOS_URL)}
                                mt={"20px"}
                                sx={{ "textDecoration": "underline", "cursor": "pointer" }}
                                variant='subtitle'
                                color='#40b4ba'
                                fontSize={"1.1em"}
                                fontWeight={"bold"}
                                textAlign={"center"}
                                mb={0}
                            >
                                VER MÁS +
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={"10px"} mb={"20px"} p={"0 20px"}>
                    <Grid item xs={12}>
                        <Typography variant='title' mb={"20px"} color={"#40b4ba"} fontSize={{"xs": "1.2em", "md": "1.6em"}}>
                            TESTIMONIOS
                        </Typography>
                        <Typography variant='p'>
                            Conoce la experiencia de formar parte de RedFID, a través de testimonios proporcionados por formadoras y formadores que ya son parte de esta red.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"} m={"20px 0"}>
                        <YoutubeIframe videoId={"36s4HzxUs6g"} videoTitle={"titles"} />
                    </Grid>
                    <Grid item xs={12} md={4} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                        <Paper sx={{ maxWidth: "300px", padding: "30px", height: "100px", display: "flex", alignItems: "center", bgcolor: "#efefef" }}>
                            <Typography variant='p'>
                                Nos dio un espacio para poder construir conocimiento sobre nuestras propias experiencias pedagógicas.
                            </Typography>
                        </Paper>
                        <Box mt={"20px"} display={"flex"} justifyContent={"center"}>
                            <Box component="img" src={kperezImage} alt="kperez" sx={{ objectFit: "cover", borderRadius: "50%", width: "90px", height: "90px" }} />
                            <Box mt={"10px"} ml={"10px"}>
                                <Typography variant='title-small' mb={"10px"} fontWeight={"bold"}>
                                    Karina Pérez
                                </Typography>
                                <Typography variant='p' fontWeight={"bold"}>
                                    Universidad de Las Américas
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                        <Paper sx={{ maxWidth: "300px", padding: "30px", height: "100px", display: "flex", alignItems: "center", bgcolor: "#efefef" }}>
                            <Typography variant='p'>
                                Creo que la comunidad RedFID es una muy buena oportunidad de diálogo con otros formadores.
                            </Typography>
                        </Paper>
                        <Box mt={"20px"} display={"flex"} justifyContent={"center"}>
                            <Box component="img" src={azamoranoImage} alt="azamorano" sx={{ objectFit: "cover", borderRadius: "50%", width: "90px", height: "90px" }} />
                            <Box mt={"10px"} ml={"10px"}>
                                <Typography variant='title-small' mb={"10px"} fontWeight={"bold"}>
                                    Alicia Zamorano
                                </Typography>
                                <Typography variant='p' fontWeight={"bold"}>
                                    Universidad de Chile
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                        <Paper sx={{ maxWidth: "300px", padding: "30px", height: "100px", display: "flex", alignItems: "center", bgcolor: "#efefef" }}>
                            <Typography variant='p'>
                                El curso que se hizo a través de esta plataforma (...) me permitió algo bien interesante: clarificar la diferencia entre innovar e investigar en educación.
                            </Typography>
                        </Paper>
                        <Box mt={"20px"} display={"flex"} justifyContent={"center"}>
                            <Box component="img" src={lcasisImage} alt="lcasis" sx={{ objectFit: "cover", borderRadius: "50%", width: "90px", height: "90px" }} />
                            <Box mt={"10px"} ml={"10px"}>
                                <Typography variant='title-small' mb={"10px"} fontWeight={"bold"}>
                                    Luis Casis
                                </Typography>
                                <Typography variant='p' fontWeight={"bold"}>
                                    Universidad de Los Lagos
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={"30px"} p={"0 20px"}>
                    <Grid item xs={12}>
                        <Typography variant='title' mb={"20px"} color={"#40b4ba"} fontSize={{"xs": "1.2em", "md": "1.6em"}}>
                            INSTITUCIONES PARTICIPANTES
                        </Typography>
                    </Grid>
                    <Grid item xs={12} m={"20px 0"}>
                        <Carousel responsive={responsiveCarousel} infinite={true} autoPlaySpeed={2000} autoPlay={true} removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}>
                            {
                                universities.map(item => <UniversityItem key={item} university={item} />)
                            }
                        </Carousel>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Grid>
    );
};

export default Home;
